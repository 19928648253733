import enUS from './locales/en-US';
import zhHK from './locales/zh-HK';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let i18nextLng = localStorage.getItem('i18nextLng')
if (!i18nextLng) {
  i18nextLng = 'zh-HK'
  localStorage.setItem('i18nextLng', i18nextLng)
}

const resources = {
  'en-US': {
    translation: enUS,
  },
  'zh-HK': {
    translation: zhHK,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: i18nextLng,
    fallbackLng: i18nextLng,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
