import React from 'react'

export const CustomerLandingUrl = {
  test: 'https://test-app.dimorder.com',
  beta: 'https://beta-app.dimorder.com',
  production: 'https://app.dimorder.com',
}

export default function ReturnAppFromPayment (props) {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white'
      }}
    >
      <img
        style={{
          width: 140,
          margin: 16,
        }}
        src={process.env.PUBLIC_URL + "/new-logo192.png"}
        aria-label="app logo"
      />
      <h3
        style={{
          color: 'black',
          margin: 4,
          fontSize: 21,
        }}
      >
        請回到 DimOrder 確認付款結果
      </h3>
      <button
        style={{
          flexShrink: 0,
          fontSize: 16,
          color: 'black',
          backgroundColor: '#ffc42b',
          borderColor: '#ffc42b',
          borderRadius: 10,
          borderWidth: 2,
          boxShadow: '0px 0px 6px #0000001a !important',
          margin: 16,
          padding: 12,
          minHeight: 40,
          minWidth: 120,
        }}
        onClick={() => {
          const { pathname, search } = window.location
          const baseUrl = CustomerLandingUrl[process.env.REACT_APP_ENV ?? 'production']
          const landingUrl = baseUrl + pathname + search
          window.open(landingUrl)
        }}
      >
        返回 App
      </button>
    </div>
  )
}