import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReturnAppFromPayment from './ReturnAppFromPayment';
import reportWebVitals from './reportWebVitals';
import './i18n';

const { search } = window.location
const params = new URLSearchParams(search)
const isReturnApp = params.get('returnApp') // 需要顯示回到 App

ReactDOM.render(
  <React.StrictMode>
    {
      isReturnApp
      ? <ReturnAppFromPayment />
      : <App />
    }
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
