import betaConfig from './beta'
import defaultConfig from './default'
import prodConfig from './prod'
import testConfig from './test'

const configs = {
  test: testConfig,
  beta: betaConfig,
  production: prodConfig,
}

export default configs[process.env.REACT_APP_ENV] || defaultConfig
